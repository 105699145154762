import React from 'react';
import { useEffect, useState } from 'react';
import { projectsData, projectsNav } from './Data';
import WorkItems from './WorkItems';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Works = () => {
    const [item, setItem] = useState({name: 'all'});
    const [projects, setProjects] = useState([]);
    const [active, setActive] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    useEffect(() => {
        if(item.name === "all") {
            setProjects(projectsData);
        }
        else {
            const newProjects = projectsData.filter((project) => {
                return project.category.toLowerCase() === item.name;
            });
            setProjects(newProjects);
        }
        setCurrentPage(1); // Reset to first page when filter changes
    }, [item]);

    const handleClick = (e, index) => {
        setItem({name: e.target.textContent.toLowerCase()})
        setActive(index);
    };

    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = projects.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(projects.length / itemsPerPage);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const prevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

  return (
    <div className="works__content">
        <div className="work__filters">
            {projectsNav.map((item, index) => {
                return (
                    <span 
                        onClick={(e) => {handleClick(e, index);}} 
                        className={`${active === index ? 'active-work' : ''} work__item`} 
                        key={index}
                    >
                        {item.name}
                    </span>
                )
            })}
        </div>

        <div className="work__projects">
            <div className="work__container container grid">
                {currentItems.map((item) => {
                    return <WorkItems item={item} key={item.id} />;
                })}
            </div>

            <div className="work__pagination">
                {totalPages > 1 ? (
                    <>
                        <button 
                            onClick={prevPage} 
                            disabled={currentPage === 1}
                            className="pagination__button"
                        >
                            <MdChevronLeft size={20} />
                        </button>
                        
                        <div className="pagination__numbers">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <span
                                    key={i + 1}
                                    onClick={() => paginate(i + 1)}
                                    className={`pagination__number ${currentPage === i + 1 ? 'pagination__number-active' : ''}`}
                                >
                                    {i + 1}
                                </span>
                            ))}
                        </div>
                        
                        <button 
                            onClick={nextPage} 
                            disabled={currentPage === totalPages}
                            className="pagination__button"
                        >
                            <MdChevronRight size={20} />
                        </button>
                    </>
                ) : (
                    <div className="pagination__placeholder"></div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Works