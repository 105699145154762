import React from 'react';
import "./about.css"
import { RiCodeBoxFill } from "react-icons/ri";
import { FaGraduationCap } from "react-icons/fa6";
import { FaGithubAlt } from "react-icons/fa";
import { VscGithubProject } from 'react-icons/vsc';

const info = () => {
  const startYear = 2020; // Change this to when you started programming
  const currentYear = new Date().getFullYear();
  const yearsOfExperience = currentYear - startYear;

  return (
    <div className="about__info grid">
        <div className="about__box">
          <i className='about__icon'>
            <RiCodeBoxFill />
          </i>
          <h3 className="about__title">Programming Experience</h3>
          <span className="about__subtitle">{yearsOfExperience} Years</span>
        </div>

        <div className="about__box">
          <i className='about__icon'>
            <VscGithubProject />
          </i>
          <h3 className="about__title">Projects Completed</h3>
          <span className="about__subtitle">10+</span>
        </div>

        <div className="about__box">
          <i className='about__icon'>
            <FaGraduationCap />
          </i>
          <h3 className="about__title">Academic Achievement</h3>
          <span className="about__subtitle">First-Class<br/>Honours</span>
        </div>
    </div>
  )
}

export default info