import React from 'react';
import { Link } from 'react-router-dom';
import "./notfound.css";

const NotFound = () => {
  return (
    <section className="notfound section">
      <div className="notfound__container container">
        <div className="notfound__content">
          <h1 className="notfound__title">404</h1>
          <h2 className="notfound__subtitle">Page Not Found</h2>
          <p className="notfound__description">
            The page you are looking for doesn't exist or has been moved.
          </p>
          <Link to="/" className="button notfound__button">
            Back to Home
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound; 