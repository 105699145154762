import React from 'react';
import "./skills.css";
import "./skills.scss";
import { FaRegPaperPlane, FaDocker } from 'react-icons/fa';
import { SiEslint, SiLabview, SiGit, SiFigma } from 'react-icons/si';
import { IoLogoFirebase } from "react-icons/io5";
import { DiFirebase } from 'react-icons/di';
import { AiFillOpenAI } from "react-icons/ai";

const Backend = () => {
  return (
    <div className="skills__content box-bottom-right">
        <h3 className="skills__title">Tools</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <SiGit size={20} />
                <div>
                    <h3 className="skills__name">Git</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                <SiFigma size={20} />
                <div>
                    <h3 className="skills__name">Figma</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                    <FaDocker size={20} />
                    <div>
                        <h3 className="skills__name">Docker</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                    <IoLogoFirebase size={20} />
                    <div>
                        <h3 className="skills__name">Firebase</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <SiEslint size={20} />
                    <div>
                        <h3 className="skills__name">ESLint</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <AiFillOpenAI size={20} />
                    <div>
                        <h3 className="skills__name">OpenAI</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Backend