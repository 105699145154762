import React, {useEffect, useRef, useState }from 'react';
import emailjs from '@emailjs/browser';
import "./contact.css";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { saberToast } from '../../toast';
import { MdEmail, MdArrowForward } from 'react-icons/md';
import { BsWhatsapp, BsMessenger } from 'react-icons/bs';
import { IoSend } from 'react-icons/io5';

const Contact = () => {
    const form = useRef();
    const initialValues = { name: "", email: "", project: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };
    
    const sendEmail = (e) => {        
        e.preventDefault();
        setFormErrors(validate(formValues));
        
        if (formValues.name.trim() === '' || formValues.email.trim() === '' || formValues.project.trim() === '') {
            saberToast.error({
                title: "Error",
                text: "Some of the fields are empty",
                delay: 200,
                duration: 3500,
                rtl: false,
                position: "top-right"
            })
            return <Contact />;
          }
        setIsSubmit(true);
        setFormValues(
            { name: "", email: "", project: "" }
        )
        emailjs.sendForm('service_eljs83r', 'template_3ft4cxr', form.current, 'slUJYScQlKDdMpoBl')
        e.target.reset()
        saberToast.success({
            title: "Email has been sent",
            text: "I will get back to you as soon as possible",
            delay: 200,
            duration: 3500,
            rtl: false,
            position: "top-right"
        })
    };

    // useEffect(() => {
    //     console.log(formErrors);
    //     if (Object.keys(formErrors).length === 0 && isSubmit) {
    //       console.log(formValues);
    //     }
    //   }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.name) {
          errors.name = "Name is required!";
        }
        if (!values.email) {
          errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
          errors.email = "This is not a valid email format!";
        }
        if (!values.project) {
          errors.project = "Please write something!";
        }
        return errors;
    };
    
  return (
    <section className="contact section" id="contact">
        <h2 className="section__title">Get in touch</h2>
        <span className="section__subtitle">- Contact Me -</span>

        <div className="contact__container container grid">
            <div className="contact__content">
                <h3 className="contact__title">Talk to me</h3>

                <div className="contact__info">
                    <div className="contact__card">
                        <MdEmail className="contact__card-icon" size={20} />

                        <h3 className="contact__card-title">Email</h3>
                        <span className="contact__card-data">bbkx226@gmail.com</span>

                        <a href="mailto:bbkx226@gmail.com" className="contact__button">
                            Write me <MdArrowForward className="contact__button-icon" size={16} />
                        </a>
                    </div>

                    <div className="contact__card">
                        <BsWhatsapp className="contact__card-icon" size={20} />

                        <h3 className="contact__card-title">Whatsapp</h3>
                        <span className="contact__card-data">Brandon</span>

                        <a href="https://api.whatsapp.com/send?phone=60162989269&text=Hi!%20I%27d%20like%20to%20get%20more%20information." 
                        className="contact__button">
                            Write me <MdArrowForward className="contact__button-icon" size={16} />
                        </a>
                    </div>

                    <div className="contact__card">
                        <BsMessenger className="contact__card-icon" size={20} />

                        <h3 className="contact__card-title">Messenger</h3>
                        <span className="contact__card-data">bbkx.0226</span>

                        <a href="https://m.me/bbkx.0226" className="contact__button">
                            Write me <MdArrowForward className="contact__button-icon" size={16} />
                        </a>
                    </div>
                </div>
            </div>

            <div className="contact__content">
                <h3 className="contact__title">Drop me a line!</h3>

                <form ref={form} onSubmit={sendEmail} className="contact__form">
                    <div className="contact__form-div">
                        <label className="contact__form-tag">Name</label>
                        <input 
                        type="text" 
                        name="name"
                        value={formValues.name}
                        className="contact__form-input"
                        placeholder="Insert your name"
                        onChange={handleChange} />
                    </div>

                    <div className="contact__form-div">
                        <label className="contact__form-tag">Mail</label>
                        <input 
                        type="email" 
                        name="email"
                        value={formValues.email}
                        className="contact__form-input"
                        placeholder="Insert your email"
                        onChange={handleChange} />
                    </div>

                    <div className="contact__form-div contact__form-area">
                        <label className="contact__form-tag">Message</label>
                        <textarea 
                        name="project"
                        value={formValues.project} 
                        cols="30" 
                        rows="10" 
                        className="contact__form-input" 
                        placeholder="Leave a message..."
                        onChange={handleChange}> 
                     </textarea>
                    </div> 
                    <button
                        type="submit" 
                        className="button button--flex"
                    >
                        Send Message
                        <IoSend className="button__icon animate-on-hover" size={18} />
                    </button>
                </form>
            </div>
        </div>
    </section>
  )
};

export default Contact