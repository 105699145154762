import React, { useState, useEffect, useRef } from 'react'
import "./about.css";
import "./about.scss";
import CV from "../../assets/Brandon_CV.pdf";
import Info from './Info';
// import "./bold.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import { Data } from "./Data";
import { saberToast } from '../../toast'
import { TypeAnimation } from 'react-type-animation';
import { IoDocumentText } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";

const About = () => {
    const [toggleState, setToggleState] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const typeAnimationRef = useRef(null);

    const toggleTab = (index) => {
        setToggleState(index);

        if (index === 1) {
            // Disable scrolling when the modal opens
            document.body.classList.add('no-scroll');
        } else {
            // Enable scrolling when the modal closes
            console.log('test')
            document.body.classList.remove('no-scroll');
        }
    };

      // Add an event listener for the 'keydown' event
    useEffect(() => {
        function handleKeyPress(event) {
            if (event.key === 'Escape') {
                // Execute toggleTab(0) when the Escape key is pressed
                document.body.classList.remove('no-scroll');
                toggleTab(0);
            }
        }

        // Add the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [toggleState]);

    useEffect(() => {
        if(toggleState === 1){
            saberToast.info({
                title: "Exit the window",
                text: "Press the ESC key or click on the résumé again",
                delay: 200,
                duration: 2500,
                rtl: false,
                position: "top-right"
            })
        }
    }, [toggleState]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Set isVisible to true when the element is intersecting
                // and to false when it's not
                setIsVisible(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );
    
        if (typeAnimationRef.current) {
            observer.observe(typeAnimationRef.current);
        }
    
        return () => {
            if (typeAnimationRef.current) {
                observer.unobserve(typeAnimationRef.current);
            }
        };
    }, []);

  return (
    <section className="about section" id="about" ref={typeAnimationRef}>
        <h2 className="section__title">About Me</h2>
        <span className="section__subtitle" style={{ marginBottom: '1.5rem' }}>- My Introduction -</span>

        <div className="about__container container grid">
            <div className="about__data">
                <Info />

                <p className="about__description about__justify">
                Strong CS background (<b>CGPA 3.89</b>) with a passion for <b>Full Stack</b>, <b>Blockchain</b> and <b>Cloud computing</b>. Actively pushing my limits through self-learning and participation in <b>hackathons</b> and relevant <b>clubs</b>. Not a fast learner, but motivated, dedicated and willing to put in the time to master new skills.
                <br/><br/>
                {isVisible && (
                    <TypeAnimation
                        sequence={[
                        'Download my résumé to see my journey!',
                        1000,
                        ]}
                        wrapper="span"
                        cursor={true}
                        repeat={0}
                        omitDeletionAnimation={true}
                    />
                )}
                </p>

                <a download="Brandon-Resume" href={CV} className="button button--flex" style={{ marginTop: '-4rem' }}>
                    Résumé
                    <IoDocumentText style={{ marginLeft: '0.5rem' }} />
                </a>
                <p className='about__remarks' onClick={() => toggleTab(1)}>
                    <MdKeyboardArrowRight style={{ position: 'relative', top: '0.2em' }}/>Preview
                </p>
                <div className={toggleState === 1 ? "about__modal active-modal" : "about__modal"}>
                    <Swiper className='about__modal-content'
                        onClick={() => toggleTab(0)}
                        loop={true}
                        grabCursor={true}
                        spaceBetween={24}
                        pagination={{
                            clickable: true,
                        }}
                        slidesPerView={1} 
                        modules={[Pagination]}
                    >
                        {Data.map(({id, image, alt}) => {
                            return (
                                <SwiperSlide key={id}>
                                    <img
                                        src={image}
                                        alt={alt}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
            <div className="frame">
            </div>
        </div>
    </section>
  )
}

export default About