import React from 'react';
import { MdArrowForward } from 'react-icons/md';

const WorkItems = ({item}) => {
  return (
    <div className="work__card wrapper box" key={item.id}>
        <img src={item.image} alt="" className='work__img '/>
        <h3 className="work__title">{item.title}</h3>
        <a href={item.link} className="work__button" target="_blank" rel="noopener noreferrer">
            Link <MdArrowForward className="work__button-icon" size={16} />
        </a>
    </div>
  )
}

export default WorkItems