import React from 'react';
import "./skills.css";
import "./skills.scss";
import { FaNodeJs } from 'react-icons/fa'; 
import { SiExpress, SiMongodb } from 'react-icons/si';
import { FaPython } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { DiMsqlServer } from "react-icons/di";


const Backend = () => {
  return (
    <div className="skills__content box-top-right">
        <h3 className="skills__title">Backend</h3>

        <div className="skills__box">
            <div className="skills__group">

                <div className="skills__data">
                <FaNodeJs size={20} />
                <div>
                    <h3 className="skills__name">Node.js</h3>
                    <span className="skills__level">Basic</span>
                </div>
                </div>

                <div className="skills__data">
                <SiExpress size={20} />
                <div>
                    <h3 className="skills__name">Express.js</h3>
                    <span className="skills__level">Basic</span>
                </div>
                </div>

                <div className="skills__data">
                    <FaPython size={20} />
                    <div>
                        <h3 className="skills__name">Python</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                    <DiMsqlServer size={20} />
                    <div>
                        <h3 className="skills__name">MS SQL</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <BiLogoPostgresql size={20} />
                    <div>
                        <h3 className="skills__name">PostgreSQL</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <SiMongodb size={20} />
                <div>
                    <h3 className="skills__name">MongoDB</h3>
                    <span className="skills__level">Basic</span>
                </div>

                
                </div>
            </div>
        </div>
    </div>
  )
}

export default Backend